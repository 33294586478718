import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet/es/Helmet';

const Welcome = () => (
  <>
    <Helmet>
      <title>Welcome page</title>
    </Helmet>
    <div className="Welcome">
      <Container>
        <h1>Welcome page</h1>
        <Link to="/task1">
          <Button variant="contained" color="primary">
            Task 1
          </Button>
        </Link>
      </Container>
    </div>
  </>
);

export default Welcome;
