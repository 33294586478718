import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import idMask from '../utils/idMask';
import additionalNumbersMask from '../utils/additionalNumbersMask';
import CustomInput from '../components/CustomInput';

const Task1 = () => {
  const initialValue = {
    name: '',
    email: '',
    emailRfc: '',
    id: '',
    idMask: '',
    phone: '',
    additionalNumbers: '',
    pin: '',
    textMessage: '',
  };

  const trimSpaces = (e) => e.target.value.replace(/\s+/g, '').trim();
  const cutSpaces = (e) => e.target.value.replace(/\s\s+/g, ' ').trim();

  return (
    <div className="Task1">
      <Helmet>
        <title>Task 1</title>
      </Helmet>
      <Container>
        <Formik
          validateOnMount
          validationSchema={Yup.object({
            name: Yup.string()
              .required('Name field is required')
              .trim('Name cannot consist only spaces')
              .matches(/^([A-Za-z\s]{2,128})$/, 'Only latin letters or spaces please')
              .max(128, 'Must be 128 characters or less')
              .min(2, 'Must be 2 characters or more'),
            email: Yup.string()
              .required('Email field is required')
              .matches(/^[a-zA-Z0-9._-]{1,64}@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]{2,}/, 'Please specify a valid email')
              .min(6, 'Must be 6 characters or more')
              .max(254, 'Must be 254 characters or less'),
            emailRfc: Yup.string()
              .required('Rfc email is required')
              .min(6, 'Must be 6 characters or more')
              .max(254, 'Must be 254 characters or less')
            // eslint-disable-next-line no-useless-escape
              .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Must be valid RFC email'),
            id: Yup.string()
              .required('Id is required')
              .min(2, 'Must be 2 characters or more')
              .max(128, 'Must be 128 characters or less')
              .matches(/^[a-z0-9_]{2,128}$/, 'Please specify a valid ID. Only low register latin letters, numbers and symbol _ is allowed'),
            idMask: Yup.string()
              .required('Id with mask is required')
              .min(2, 'Must be 2 characters or more'),
            phone: Yup.string()
              .required('Phone field is required')
            // eslint-disable-next-line no-useless-escape
              .matches(/^[+]?38\(?(0\d{2})\)?\s\d{3}\s-\s\d{2}\s-\s\d{2}/, 'Please specify UA phone number in international format'),
            additionalNumbers: Yup.string()
              .required('Additional numbers field is required')
              .matches(/(\D*\d){7}/, 'Specify at list 7 digits'),
            pin: Yup.string()
              .required('Pin code is required')
              .matches(/^\d{4}-\d{4}/, 'Please specify a valid pin code in following format 0000-0000'),
            textMessage: Yup.string()
              .max(500, 'Must be 500 characters or less'),
          })}
          initialValues={initialValue}
          onSubmit={(values, { setSubmitting, resetForm, validateForm }) => {
            setTimeout(() => {
              window.alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
              resetForm();
              validateForm().then();
            }, 1000);
          }}
        >
          {({ isValid, validateField, isSubmitting }) => (
            <Form noValidate autoComplete="off">
              <CustomInput
                label="Name"
                name="name"
                validateField={validateField}
                customBlur={cutSpaces}
              />
              <CustomInput
                label="Email"
                name="email"
                validateField={validateField}
                customBlur={trimSpaces}
              />
              <CustomInput
                label="Email RFC"
                name="emailRfc"
                validateField={validateField}
                customBlur={trimSpaces}
              />
              <CustomInput
                label="ID"
                name="id"
                validateField={validateField}
                customBlur={trimSpaces}
              />
              <CustomInput
                label="ID With Mask"
                name="idMask"
                mask={idMask}
              />
              <CustomInput
                label="UA Phone number"
                name="phone"
                mask="+38(099) 999 - 99 - 99"
                maskSymbol="_"
              />
              <CustomInput
                label="Additional numbers"
                name="additionalNumbers"
                mask={additionalNumbersMask}
                validateField={validateField}
                customBlur={cutSpaces}
              />
              <CustomInput
                label="Pin code"
                name="pin"
                mask="9999-9999"
                maskSymbol="_"
              />
              <CustomInput
                label="Text message(not required)"
                name="textMessage"
                multiline
                validateField={validateField}
                customBlur={(e) => e.target.value
                  .replace(/(\r\n|\r|\n)+/g, '\n\n')
                  .replace(/  +/g, ' ').trim()}
              />
              <Button
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="contained"
                color="primary"
                title="Submit"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default Task1;
