import { useField } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputMask from 'react-input-mask';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const CustomInput = ({ label, mask, ...rest }) => {
  const {
    placeholder, id, name, customBlur,
  } = rest;
  const [{
    value, onChange, onBlur,
  }, { touched, error }, { setValue }] = useField(rest);

  const inputBlur = useCallback((e) => {
    if (customBlur) {
      setValue(customBlur(e));
      setTimeout(() => {
        rest.validateField(name);
      }, 10);
    }
    onBlur(e);
  }, [customBlur, rest.validateField, name]);

  return (
    <FormControl
      variant="outlined"
      className="FormControl"
      fullWidth
      error={!!(touched && error)}
    >
      {label
      && <InputLabel htmlFor={id || name}>{label}</InputLabel>}
      {
        mask ? (
          <InputMask
            title={label}
            mask={mask}
            maskPlaceholder={rest.maskSymbol || null}
            value={value}
            onChange={onChange}
            onBlur={inputBlur}
            placeholder={placeholder}
          >
            <OutlinedInput
              name={name}
            />
          </InputMask>
        ) : (
          <OutlinedInput
            title={label}
            name={name}
            value={value}
            onChange={onChange}
            multiline={rest.multiline}
            onBlur={inputBlur}
            placeholder={placeholder}
          />
        )
      }
      <FormHelperText id="my-helper-text">{error}</FormHelperText>
    </FormControl>
  );
};

export default memo(CustomInput);

CustomInput.propTypes = {
  label: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

CustomInput.defaultProps = {
  label: '',
  mask: '',
};
