import React from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Task1 from './pages/Task1';
import Welcome from './pages/Welcome';

const useStyles = makeStyles(() => ({
  toolbar: {
    justifyContent: 'center',
    fontSize: 24,
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <BrowserRouter>
      <AppBar position="fixed">
        <ToolBar className={classes.toolbar}>
          Test 2020 ABZ.AGENCY Kotlinskii O.
        </ToolBar>
      </AppBar>
      <main>
        <Switch>
          <Route path="/" exact>
            <Welcome />
          </Route>
          <Route path="/task1">
            <Task1 />
          </Route>
          <Redirect to="/" />
        </Switch>
      </main>
    </BrowserRouter>
  );
};

export default App;
